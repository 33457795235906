import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Tabs, Tab } from 'react-bootstrap';
import { getTodayDateISO, refreshPage } from "../../../utilities/common";

// css
import '../../common.css';
import './FreeService.css';

// components
import Navbar from '../../navbar';
import TableTemplate from '../../table-template';
import Pagination from '@material-ui/lab/Pagination';
import ReactLoading from 'react-loading';

// sample data (temp)
import { TextArea } from "semantic-ui-react";

// API
import { toast, ToastContainer } from "react-toastify";
import { getAllEmployees } from "../../../utilities/api_calls/employeeApi";
import { availFree, getAllFreeService, getAllFreeServiceHistory, searchFreeServices } from "../../../utilities/api_calls/freeServicesAPI.js";
import { availService, getAllServices } from "../../../utilities/api_calls/servicesApi";
import NoDataPrompt from '../../../utilities/no-data-prompt';
import { Navigate, useParams } from 'react-router-dom';
import { getAllCustomers } from '../../../utilities/api_calls/customerApi';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';

function FreeService() {
  const [showLoader, setShowLoader] = useState(true);
  const { page } = useParams();
  const [tabSelected, setTabSelected] = useState("available");
  const [filter, setFilter] = useState("")
  // Pagination
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesAvailed, setPagesAvailed] = useState(1);
  const [currentPageAvailed, setCurrentPageAvailed] = useState(1);

  function handlePageChange(event, value) {
    // setCurrentPage(value === null ? 1 : value)
    setCurrentPage(value)
  }
  function handlePageChange2(event, value) {
    setCurrentPageAvailed(value === null ? 1 : value)
    // setCurrentPageAvailed(value)
  }

  // --- Avail Popup/Modal --- //
  const [showAvail, setShowAvail] = useState(false);
  const handleCloseAvail = () => setShowAvail(false);
  const handleShowAvail = () => setShowAvail(true);

  // --- GET ALL CUSTOMERS ELIGIBLE FOR FREE SERVICE --- //
  const [freeService, setFreeService] = useState([])
  const [freeServiceHistory, setFreeServiceHistory] = useState([])

  async function fetchFreeService() {
    setShowLoader(true);
    setTimeout(() => setShowLoader(false), 1000);

    const response = await getAllFreeService(currentPage);

    if (response.error) {
      setFreeService([]);
    } else {
      setFreeService(response.data.data.free_services);
      setPages(parseInt(response.data.data.size) / 10)
    }

    setShowLoader(false);
  }

  async function fetchFreeServiceHistory() {
    setShowLoader(true);
    setTimeout(() => setShowLoader(false), 1000);

    const response = await getAllFreeServiceHistory(filter, currentPageAvailed);
    if (response.error) {
      setFreeServiceHistory([]);
    } else {
      setFreeServiceHistory(response.data.data.history);
      setPagesAvailed(parseInt(response.data.data.size) / 10)
    }
    setShowLoader(false);
  }

  //Search Free Services
  async function searchThis(name) {
    setFreeService([]);
    if (name === "") {
      setFreeService(freeService => [])
      fetchFreeService();
    }
    else {
      const response = await searchFreeServices(name, "available");
      if(response.data){
        setFreeService(response.data.data.free_services);
      }
      else{
        setFreeService([])
      }
    }
  }
  async function searchThisHistory(name) {
    setFreeService([]);
    if (name === "") {
      setFreeServiceHistory(freeServiceHistory => [])
      fetchFreeServiceHistory();
    }
    else {
      const response = await searchFreeServices(name, "availed");
      if(response.data){
        setFreeServiceHistory(response.data.data.free_services);
      }else{
        setFreeServiceHistory([])
      }
    }
  }

  // --- AVAIL FREE SERVICE
  const [hasService, setHasService] = useState(true);
  const [hasDateAvailed, setHasDateAvailed] = useState(true);
  const [hasDermTech, setHasDermTech] = useState(true);
  const [click, setClick] = useState(false);

  async function availFreeService(e) {
    // e.preventDefault()
    const { service, date_availed, derm_tech } = availServices

    let validation = true;

    if (!service) {
      setHasService(false)
      validation = false
    } else {
      setHasService(true)
    }
    if (!date_availed) {
      setHasDateAvailed(false)
      validation = false
    } else {
      setHasDateAvailed(true)
    }
    if (!derm_tech) {
      setHasDermTech(false)
      validation = false
    } else {
      setHasDermTech(true)
    }
    if (validation) {
      // if (click === false) {
        setClick(true)
        const response = await availFree(availServices, customerId);
        console.log(response)
        if (response.data) {
          toast.success(response?.data?.message?.success)
          setTimeout(function () {
            refreshPage();
          }, 3000);
        }else{
          toast.error(response?.error?.data?.messages?.error)
        }
        // else if (response.data.data.status === 401) {
        //   toast.error(response.data.data.message.success)
        // } 
       
      // }
    }

  }

  /** CUSTOMERs **/
  const [customers, setCustomers] = useState([]);

  async function fetchCustomers() {
    setCustomers([]);
    const response = await getAllCustomers();
    var people = response.data.data.customers.sort((a, b) =>
      a.first_name.toLowerCase() > b.first_name.toLowerCase()
        ? 1
        : b.first_name.toLowerCase() > a.first_name.toLowerCase()
        ? -1
        : 0
    );
    setCustomers(people)
  }

  // -- Handle modal
  const [customerId, setCustomerId] = useState("")
  const [redirect, setRedirect] = useState(false);

  function onclickAvail(customerId) {
    const id = customerId
    handleShowAvail()
    setCustomerId(id)
  }
  function onclickContact(customerId) {
    const id = customerId
    setRedirect(true)
    setCustomerId(id)
  }

  // Form Changes
  const [availServices, setAvailServices] = useState({
    service: '',
    derm_tech: '',
    date_availed: getTodayDateISO(),
    remarks: ''
  });
  console.log(availServices)
  const handleAvailChange = (e) => {
    const { name, value } = e.target;
    setAvailServices(prevState => ({
      ...prevState,
      [name]: value
    }));
  }


  // Fetch services
  const [hasFetchedServices, setHasFetchedServices] = useState(false);
  const [hasFetchedServicesHistory, setHasFetchedServicesHistory] = useState(false);
  const [viewServices, setViewServices] = useState([]);
  async function fetchServices() {
    const response = await getAllServices();
    setViewServices(response.data.data.services)
  }

  // Fetch Employees
  const [employees, setEmployees] = useState([]);
  async function fetchEmployees() {
    const response = await getAllEmployees();
    setEmployees(response.data.data.employees);
  }

  // --- Render --- //

  function renderTable() {
    if (freeService.length < 1) {
      return (
        <NoDataPrompt text="available free services" />
        // <span className="no-data-prompt">No Customers Eligible for Free Services Found!</span>
      )
    } else {
      return (
        <>
          <TableTemplate
            tableType='sample_free_service'
            tableHeaders={["Customer Name", "Contact No.", "No. of Free Services", ""]}
            tableData={freeService}
            // tableFuncs={[handleShowAvail]}
            tableFuncs={[onclickAvail, onclickContact]}
            currentPage={currentPage}
          />
          <Pagination count={parseInt(pages > 1 ? pages : 1)} page={currentPage} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange} showFirstButton showLastButton />
        </>
      )
    }
  }

  function renderTableAvailed() {
    if (freeServiceHistory.length < 1) {
      return (
        <NoDataPrompt text="available free services" />
        // <span className="no-data-prompt">No Customers Eligible for Free Services Found!</span>
      )
    } else {
      return (
        <>
          <TableTemplate
            tableType='sample_free_service_history'
            tableHeaders={["Customer Name", "Service", "Serviced By", "Availed Date"]}
            tableData={freeServiceHistory}
            // tableFuncs={[handleShowAvail]}
            // tableFuncs={[onclickAvail, onclickContact]}
            currentPage={currentPageAvailed}
          />
          <Pagination count={parseInt(pagesAvailed > 1 ? pagesAvailed : 1)} page={currentPageAvailed} shape="rounded" defaultPage={1} siblingCount={2} onChange={handlePageChange2} showFirstButton showLastButton />
        </>
      )
    }
  }

    // --- API CALLS --- //
  useEffect(() => {
    fetchServices();
    fetchEmployees();
    fetchCustomers();
  }, [])
  useEffect(() => {
    if(!hasFetchedServices && currentPage ? currentPage : 1 && tabSelected === "available") {
      fetchFreeService();
      setHasFetchedServices(true)
    }
  }, [tabSelected, currentPage])

  useEffect(() => {
    if(!hasFetchedServicesHistory && currentPageAvailed ? currentPageAvailed : 1  && tabSelected === "availed"){
      fetchFreeServiceHistory();
      setHasFetchedServicesHistory(true)
    }
  }, [tabSelected, currentPageAvailed, filter !== "" ? filter : ""])

  if (redirect === true) {
    var link = "/customer" + '/details/' + currentPage + "/" + customerId;
    return (
      // window.open("/customer" + '/details/' + currentPage + "/" + customerId, "_blank")
      <Navigate to={link} />
    )
  }

  return (
    <>
      <div>
        <Navbar />
        <div className="active-cont">
          <Fragment>
            <ToastContainer />

            <div className="d-flex flex-row justify-content-between cont-section">
              <h1 className="cont-sect-header">Free Service</h1>
              {tabSelected === "available" &&
                <div className="search-wrapper w-50">
                  <input type="search" class="form-control search-indent" placeholder="Search by Name" onChange={(e) => searchThis(e.target.value)}/>
                  <div className="search-icon">
                    <FontAwesomeIcon
                      className="icon"
                      icon={'search'}
                      aria-hidden="true"
                      alt={'search'}
                      fixedWidth
                    />
                  </div>
                </div>
              }
              {tabSelected === "availed" &&
                <div className="search-wrapper w-50">
                  <input type="search" class="form-control search-indent" placeholder="Search by Customer" onChange={(e) => searchThisHistory(e.target.value)}/>
                  <div className="search-icon">
                    <FontAwesomeIcon
                      className="icon"
                      icon={'search'}
                      aria-hidden="true"
                      alt={'search'}
                      fixedWidth
                    />
                  </div>
                </div>
              }
            </div>
            {/* {tabSelected === "availed" && */}
              {/* <div className="row filter-add-cont">
                <div className="col">
                  * FILTER
                  <div className="filter-cont-2">
                    <div className="filter-label">
                      FILTER BY:
                      <select
                        className="filter-dropdown-input"
                        defaultValue={""}
                        name="customer_id"
                        onChange={(e) => setFilter(e.target.value)}
                      >
                        <option selected value="" hidden>
                          Select
                        </option>
                        <option selected value="">
                          Select
                        </option>
                        {customers.map((employee) => {
                            return (<option key={employee.id} value={employee.id}>{employee.first_name.toUpperCase() + " " + employee.last_name.toUpperCase()}</option>)
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
              </div> */}
            {/* } */}
            <div className="cont-section-novertpad free-service-table">
              <Tabs
                defaultActiveKey="available_giftcert"
                id="customer-transaction"
                variant="pills"
                className="customer-transaction-tab"
                activeKey={tabSelected}
                onSelect={(k) => setTabSelected(k)}
              >
                <Tab
                  eventKey="available"
                  title="Available"
                  onSelect={() => setTabSelected("available")}
                >
                  {showLoader ?
                    <ReactLoading type="spinningBubbles" color="#d4271c" height={200} width={100} className='list-loader' />
                    : renderTable()
                  }
                </Tab>
                <Tab
                  eventKey="availed"
                  title="Availed"
                  onSelect={() => setTabSelected("availed")}
                >
                  {showLoader ?
                    <ReactLoading type="spinningBubbles" color="#d4271c" height={200} width={100} className='list-loader' />
                    : renderTableAvailed()
                  }
                </Tab>
              </Tabs>
            </div>

          </Fragment>

          {/* ADD EMPLOYEE MODAL*/}
          <Modal show={showAvail} onHide={handleCloseAvail} size="lg" className="custom-modal-style" centered>
            <Form>
              <Modal.Header className="custom-modal-header d-flex justify-content-end" closeButton></Modal.Header>
              <Modal.Body className="px-5 py-1">
                <h1 className="custom-modal-body-title">Add Service</h1>
                <Container>
                  <Row className="nc-modal-custom-row">
                    <Col>Service</Col>
                    <Col xs={1}>:</Col>
                    <Col xs={7}>
                      <Form.Select name="service" className="nc-modal-custom-input" onChange={e => handleAvailChange(e)} >
                        <option value="" hidden>Select</option>
                        {viewServices && viewServices.map((data, index) =>
                          <option key={index} value={data.id}>{data.name}</option>
                        )}
                      </Form.Select>
                      {hasService === false && (
                        <div className="validity-error">
                          Service is required*
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className="nc-modal-custom-row">
                    <Col>Date Availed</Col>
                    <Col xs={1}>:</Col>
                    <Col xs={7}><Form.Control type="date" name="date_availed" placeholder="Enter password" value={availServices.date_availed} className="nc-modal-custom-input" onChange={e => handleAvailChange(e)} />
                      {hasDateAvailed === false && (
                        <div className="validity-error">
                          Date Availed is required*
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className="nc-modal-custom-row">
                    <Col>Derm Tech</Col>
                    <Col xs={1}>:</Col>
                    <Col xs={7}>
                      <Form.Select name="derm_tech" className="nc-modal-custom-input" onChange={e => handleAvailChange(e)} >
                        <option value="" hidden>Select</option>
                        {employees.map((employee) => {
                          return (<option key={employee.id} value={employee.id}>{employee.name}</option>)
                        })
                        }
                      </Form.Select>
                      {hasDermTech === false && (
                        <div className="validity-error">
                          Derm tech is required*
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className="nc-modal-custom-row">
                    <Col xs={4}>Remarks</Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextArea rows={5} name="remarks" className="nc-text-area-input" onChange={e => handleAvailChange(e)} />
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer className="border-0 px-5">
                <div className="d-flex flex-row justify-content-between">
                  <div className="btn-conf-wrapper ms-auto">
                    {click === true && (
                      <div className="d-flex justify-content-center">
                        <div className="btn-conf-wrapper ms-auto">
                          <Button type="submit" className="px-5 pb-1 mb-2">
                            <ReactLoading type="spinningBubbles" color="#d4271c" height={25} width={20} />
                          </Button>
                        </div>
                      </div>
                    )}
                    {click === false && (
                      <Button 
                        // type="submit" 
                        className="px-5 pb-1 mb-2" 
                        onClick={(e) => availFreeService(e)}
                      >
                        avail
                      </Button>
                    )}
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  )
}

export default FreeService;