import { formatDate, formatMDY, getBranch, getToken, getUser, tokenExpiry } from '../common';
import { getAPICall } from './axiosMethodCalls';

/***************************
 * FREE SERVICE
 ***************************/

// Get All Customer Eligible for Free Service

export const getAllFreeService = async (currentPage) => {
  try {
    const response = await getAPICall(window.$link + 'free_services/getAll', {
      api_key: window.$api_key,
      token: getToken().replace(/['"]+/g, ''),
      requester: getUser(),
      branch_id: getBranch(),
      offset: currentPage ? currentPage : 1,
    });
    return ({ data: response });
  } catch (error) {
    tokenExpiry(error);
    return ({ error: error });
  }
}

export const getAllFreeServiceHistory = async (filter, currentPage) => {
  try {
    const response = await getAPICall(window.$link + 'free_services/history', {
      api_key: window.$api_key,
      token: getToken().replace(/['"]+/g, ''),
      requester: getUser(),
      branch_id: getBranch(),
      customer_id: filter,
      offset: currentPage ? currentPage : 1,
    });
    return ({ data: response });
  } catch (error) {
    tokenExpiry(error);
    return ({ error: error });
  }
}

export const getNoFreeServices = async (cust_id) => {
  try {
    const response = await getAPICall(window.$link + 'free_services/get_no_of_free_services', {
      api_key: window.$api_key,
      token: getToken().replace(/['"]+/g, ''),
      requester: getUser(),
      branch_id: getBranch(),
      customer_id: cust_id,
    });
    return ({ data: response });
  } catch (error) {
    tokenExpiry(error);
    return ({ error: error });
  }
}

// Avail Free Service
export const availFree = async (data, customer_id) => {
  try {
    const response = await getAPICall(window.$link + 'free_services/avail_free_service', {
      api_key: window.$api_key,
      token: getToken().replace(/['"]+/g, ''),
      requester: getUser(),
      branch_id: getBranch(),
      customer_id: customer_id,
      service_id: data.service,
      serviced_by: data.derm_tech,
      serviced_on: formatMDY(data.date_availed),
      remarks: data.remarks
    });
    return { data: response.data };
  } catch (error) {
    tokenExpiry(error);
    return  { error: error.response };
  }
}

//Get
export const searchFreeServices = async (name, type) => {
  try {
      const response = await getAPICall(window.$link + 'free_services/search', {
          api_key: window.$api_key,
          token: getToken().replace(/['"]+/g, ''),
          requester: getUser(),
          branch_id: getBranch().replace(/['"]+/g, ''),
          name: name,
          type: type,
      });
      return ({ data: response });
  } catch (error) {
      tokenExpiry(error);
      return ({ error: error });
  }
}
