import { Table, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import freeIcon from "../images/icons/free-icon.png";
import usedIcon from "../images/icons/used-icon.png";
import "./common.css";
import {
  formatDate,
  formatNum,
  getRoleId,
  numberFormat,
} from "../utilities/common";
import moment from "moment";

// Notes (might be better to develop a better system for this idk haha)
// tableFuncs - should where modal triggers are placed
// tableFuncs[0] = delete
// tableFuncs[1] = edit
// tableFuncs[2] = view
// tableFuncs[3][?] = unique funcs
// tableNavgn - should where new-page triggers are placed
// hasFooter - either true or false

function TableTemplate({
  tableType,
  tableHeaders,
  tableData,
  tableFuncs,
  action,
  setAction,
  tableNavgn,
  hasFooter,
  footerData,
  currentPage,
}) {
  const role = getRoleId();

  // const [branchName, setBranchName] = useState("");

  // async function fetchBranch(id) {
  //     const response = await getBranchName(id);
  //     setBranchName(response.data.data.name)
  // }

  // set all row fields in the table
  const rowFields = (row, index) => {
    if (tableType === "customer") {
      const dateAdded = formatDate(row.added_on);
      return (
        <tr key={row.id}>
          <td>{row.first_name}</td>
          <td>{row.last_name}</td>
          <td>{row.contact_no}</td>
          <td>{dateAdded}</td>
          <td className="srv-conf-wrapper">
            <Button
              type="button"
              onClick={() => tableNavgn[0](row.id, currentPage)}
              className="px-3 py-0"
            >
              view
            </Button>
          </td>
        </tr>
      );
    } else if (tableType === "employee") {
      return (
        <tr key={row.id}>
          <td>
            <FontAwesomeIcon
              role="button"
              onClick={() => tableFuncs[0](row.id)}
              className="align-self-center mx-1"
              icon={"trash"}
            />
            <FontAwesomeIcon
              role="button"
              onClick={() => tableFuncs[1](row.id)}
              className="align-self-center mx-1"
              icon={"pen"}
            />
          </td>
          <td>{row.name ? row.name : row.first_name + " " + row.last_name}</td>
          <td>{row.role}</td>
          <td className="srv-conf-wrapper">
            <Button
              type="button"
              onClick={() => tableFuncs[2](row.id)}
              className="px-3 py-0"
            >
              view
            </Button>
          </td>
        </tr>
      );
    } else if (tableType === "inventory") {
      return (
        <tr key={row.id}>
          <td>
            <FontAwesomeIcon
              role="button"
              onClick={() => tableFuncs[0](row.id)}
              className="align-self-center mx-1"
              icon={"trash"}
            />
            <FontAwesomeIcon
              role="button"
              onClick={() => tableFuncs[1](row.id)}
              className="align-self-center mx-1"
              icon={"pen"}
            />
          </td>
          <td>{row.name}</td>
          <td>{row.IMELDA}</td>
          <td>{row.R_MARASBAS}</td>
          <td>{row.R_ORMOC}</td>

          <td className="inv-conf-wrapper">
            <select
              onChange={(e) =>
                setAction(e.target.value + "@" + row.id + "@" + currentPage)
              }
              className={"dropdown-input"}
            >
              <option value="" hidden>
                Select
              </option>
              {getRoleId() === "4" && (
                <option value="stock-in">Stock In</option>
              )}
              <option value="view">View</option>
            </select>
          </td>
        </tr>
      );
    } else if (tableType === "service") {
      return (
        <tr key={row.id}>
          <td>
            <FontAwesomeIcon
              role="button"
              onClick={() => tableFuncs[0](row.id)}
              className="align-self-center mx-1"
              icon={"trash"}
            />
            <FontAwesomeIcon
              role="button"
              onClick={() => tableFuncs[1](row.id)}
              className="align-self-center mx-1"
              icon={"pen"}
            />
          </td>
          <td className="new-service-width">{row.name}</td>
          <td className="new-service-right ">
            {parseFloat(row.price)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </td>

          <td className="srv-conf-wrapper">
            <Button
              type="button"
              onClick={() => tableFuncs[2](row.id)}
              className="service px-3 py-0"
            >
              view
            </Button>
          </td>
        </tr>
      );
    } else if (tableType === "package") {
      return (
        <tr key={row.id}>
          <td>
            <FontAwesomeIcon
              role="button"
              onClick={() => tableFuncs[0](row.id)}
              className="align-self-center mx-1"
              icon={"trash"}
            />
            <FontAwesomeIcon
              role="button"
              onClick={() => tableFuncs[1](row.id)}
              className="align-self-center mx-1"
              icon={"pen"}
            />
          </td>
          <td>{row.name}</td>
          <td>
            {parseFloat(row.price)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </td>
          <td className="srv-conf-wrapper">
            <Button
              type="button"
              onClick={() => tableFuncs[2](row.id)}
              className="px-3 py-0"
            >
              view
            </Button>
          </td>
        </tr>
      );
    } else if (tableType === "expenses") {
      //fetchBranch(row.branch_id);
      return (
        <tr key={row.ID}>
          <td>
            <FontAwesomeIcon
              role="button"
              onClick={() => tableFuncs[0](row.ID)}
              className="align-self-center mx-1"
              icon={"trash"}
            />
            <FontAwesomeIcon
              role="button"
              onClick={() => tableFuncs[1](row.ID)}
              className="align-self-center mx-1"
              icon={"pen"}
            />
          </td>
          <td>{formatDate(row.DATE_ADDED)}</td>
          <td>{row.BRANCH}</td>
          <td>{row.ITEM}</td>
          <td>{row.QUANTITY}</td>
          <td>
            {parseFloat(row.PRICE)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </td>
          <td>
            {parseFloat(row.GRAND_TOTAL)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </td>
          <td>{row.REMARKS}</td>
          <td>{row.ADDED_BY}</td>
        </tr>
      );
    } else if (tableType === "available_gift_certificates") {
      return (
        <tr key={row.ID}>
          <td>
            <input
              type="checkbox"
              name="CHECKLIST_DELETE"
              onChange={(e) => tableFuncs[2](e, row.INDEX, row.TYPE)}
            />
          </td>
          <td>{formatDate(row.DATE_ADDED)}</td>
          <td>{row.CODE}</td>
          <td>{row.NAME}</td>
          <td>{row.BRANCH}</td>
          <td>
            {parseFloat(row.AMOUNT)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </td>
          <td>
            {role === "4" && (
              <>
                <FontAwesomeIcon
                  role="button"
                  onClick={() => tableFuncs[0](row.ID)}
                  className="align-self-center mx-1"
                  icon={"trash"}
                />
                <FontAwesomeIcon
                  role="button"
                  onClick={() => tableFuncs[1](row.ID)}
                  className="align-self-center mx-1"
                  icon={"pen"}
                />
              </>
            )}
          </td>
        </tr>
      );
    } else if (tableType === "sample_purchased_gift_certificates") {
      return (
        <tr key={row.id}>
          <td>
            <input
              type="checkbox"
              name="CHECKLIST_DELETE"
              onChange={(e) => tableFuncs[2](e, index, row.TYPE)}
            />
          </td>
          <td>{formatDate(row.DATE_ADDED)}</td>
          <td>{row.CODE}</td>
          <td>{row.NAME}</td>
          <td>{row.BRANCH}</td>
          <td>{row.CUSTOMER}</td>
          <td>
            {role === "4" && (
              <>
                <FontAwesomeIcon
                  role="button"
                  onClick={() => tableFuncs[0](row.ID)}
                  className="align-self-center mx-1"
                  icon={"trash"}
                />
                <FontAwesomeIcon
                  role="button"
                  onClick={() => tableFuncs[1](row.ID)}
                  className="align-self-center mx-1"
                  icon={"pen"}
                />
              </>
            )}
          </td>
          {/* <td className="srv-conf-wrapper">
                        <Button type="button" onClick={() => tableFuncs[2](row.ID)} className="px-3 py-0" >Avail</Button>
                    </td> */}
        </tr>
      );
    } else if (tableType === "sample_used_gift_certificates") {
      return (
        <tr key={row.ID}>
          <td>
            <input
              type="checkbox"
              name="CHECKLIST_DELETE"
              onChange={(e) => tableFuncs[2](e, index, row.TYPE)}
            />
          </td>
          <td>{formatDate(row.DATE_ADDED)}</td>
          <td>{formatDate(row.DATE_USED)}</td>
          <td>{row.CODE}</td>
          <td>{row.NAME}</td>
          <td>{row.BRANCH}</td>
          <td>{row.DERM_TECH}</td>
          <td>{row.CUSTOMER}</td>
          <td>
            {role === "4" && (
              <>
                <FontAwesomeIcon
                  role="button"
                  onClick={() => tableFuncs[0](row.ID)}
                  className="align-self-center mx-1"
                  icon={"trash"}
                />
                <FontAwesomeIcon
                  role="button"
                  onClick={() => tableFuncs[1](row.ID)}
                  className="align-self-center mx-1"
                  icon={"pen"}
                />
              </>
            )}
          </td>
        </tr>
      );
    } else if (tableType === "sample_commission_report") {
      return (
        <tr key={row.id}>
          <td>{formatDate(row.DATE)}</td>
          <td>{row.PARTICULARS}</td>
          <td>{row.NAME}</td>
          <td>{row.TRANSACTION_ID}</td>
          <td>{numberFormat(row.COMMISISON)}</td>
        </tr>
      );
    } else if (tableType === "sample_balance_report") {
      return (
        <tr key={row.id}>
          <td>{row.customer_name}</td>
          <td>
            {row.total_balance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </td>
          <td className="srv-conf-wrapper">
            <Button
              type="button"
              onClick={() => tableNavgn[0]()}
              className="px-3 py-0"
            >
              view
            </Button>
          </td>
        </tr>
      );
    } else if (tableType === "sample_customer_report") {
      return (
        <tr key={row.id}>
          <td>{row.date_purchased}</td>
          <td>{row.branch}</td>
          <td>{row.name}</td>
          <td>{row.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</td>
          <td>{row.balance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</td>
        </tr>
      );
    } else if (tableType === "balance") {
      return (
        <tr key={row.ID}>
          <td>{row.CUSTOMER}</td>
          <td>
            {numberFormat(
              row["SUM(booking.grand_total - booking.paid_amount)"]
            )}
          </td>
          <td className="srv-conf-wrapper">
            <Button
              type="button"
              onClick={() => tableNavgn[0](row.ID)}
              className="px-3 py-0"
            >
              View
            </Button>
          </td>
        </tr>
      );
    } else if (tableType === "customer_balance_details") {
      return (
        <tr key={row.ID}>
          <td>{formatDate(row.DATE_PURCHASED)}</td>
          <td>{row.BRANCH}</td>
          <td>{row.NAME}</td>
          <td>{numberFormat(row.AMOUNT)}</td>
          <td>{numberFormat(row.BALANCE)}</td>
        </tr>
      );
    } else if (tableType === "free_service_report") {
      return (
        <tr key={row.id}>
          <td>{formatDate(row.DATE_AVAILED)}</td>
          <td>{row.BRANCH}</td>
          <td>{row.CUSTOMER}</td>
          <td>{row.SERVICE}</td>
          <td>{row.DERM_TECH}</td>
        </tr>
      );
    } else if (tableType === "customer_available_service") {
      function renderFreeIcon() {
        return <img height={24} src={freeIcon} alt={"free-icon"}></img>;
      }

      return (
        <tr key={row.id}>
          <td>{formatDate(row["DATE(bpd.added_on)"])}</td>
          <td>{row.BRANCH}</td>
          <td>{row.PACKAGE}</td>
          <td>{row.SERVICE}</td>
          <td className="btn-conf-wrapper">
            <Button
              type="button"
              onClick={() => tableFuncs[3][0]("bpd", row.id)}
              className="px-3 py-0"
            >
              avail
            </Button>
          </td>
          <td>
            <span>{row.is_free === "1" ? renderFreeIcon() : ""}</span>
          </td>
        </tr>
      );
    }
    // else if (tableType === 'customer_purchase') {
    //     return (
    //         <tr key={row.id}>
    //             <td>{row.date_purchased}</td>
    //             <td>{(row.total_amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
    //             <td>{(row.balance).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</td>
    //             <td>{row.derm_tech}</td>
    //             <td className="btn-conf-wrapper">
    //                 <Button type="button" onClick={() => tableFuncs[3][0]()} className="px-3 py-0">pay now</Button>
    //             </td>
    //         </tr>
    //     )
    // }
    else if (tableType === "sample_daily_sales_report") {
      return (
        <tr key={row.ID}>
          <td>{formatDate(row.DATE)}</td>
          <td>{row.CUSTOMER}</td>
          <td>{row.ID}</td>
          <td>{numberFormat(row.SUB_TOTAL)}</td>
          <td>{numberFormat(row.DISCOUNT)}</td>
          <td>{numberFormat(row.GRAND_TOTAL)}</td>
          <td>{numberFormat(row.PAID_AMOUNT)}</td>
          <td>{numberFormat(row.BALANCE)}</td>
          <td>{row.payment_type}</td>
          {/* <td>{row.DERM_TECH}</td> */}
          <td className="srv-conf-wrapper">
            {row.payment_type !== "Free" &&
              <Button
                type="button"
                onClick={() => tableFuncs[3][0](row.ID, row)}
                className="px-3 py-0"
              >
                view
            </Button>
            }
          </td>
        </tr>
      );
    } else if (tableType === "sales_payment_history") {
      return (
        <tr>
          <td>{formatDate(row?.DATE_PAID)}</td>
          <td>{row?.PAYMENT_TYPE}</td>
          <td>{numberFormat(row?.PAID_AMOUNT)}</td>
          {/* <td>{numberFormat(row.BALANCE)}</td> */}
          <td>{row?.REMARKS}</td>
        </tr>
      );
    } else if (tableType === "sample_daily_products_purchased_report") {
      return (
        <tr key={row.ID}>
          <td>{formatDate(row.DATE)}</td>
          <td>{row.BRANCH}</td>
          <td>{row.CUSTOMER}</td>
          <td>{row.PRODUCT}</td>
          <td>{row.QUANTITY}</td>
          <td>{numberFormat(row.GRAND_TOTAL)}</td>
          <td>{row.SALES_BY}</td>
        </tr>
      );
    } else if (tableType === "sample_free_service") {
      return (
        <tr key={row.ID}>
          <td>{row.CUSTOMER}</td>
          <td>{row.CONTACT_NO}</td>
          <td className="align-self-center mx-1">{row.NO_OF_FREE_SERVICES}</td>
          <td></td>
          <td className="srv-conf-wrapper left" align="right">
            <Button
              type="button"
              onClick={() => tableFuncs[0](row.ID)}
              className="px-3 mx-2 py-0"
            >
              AVAIL
            </Button>
            <Button
              type="button"
              onClick={() => tableFuncs[1](row.ID)}
              className="px-3 py-0"
            >
              VIEW
            </Button>
          </td>
        </tr>
      );
    }else if (tableType === "sample_free_service_history") {
      const dateAdded = formatDate(row.AVAILED_ON);
      return (
        <tr key={row.ID}>
          <td>{row.CUSTOMER}</td>
          <td>{row.SERVICE}</td>
          <td>{row.SERVICED_BY}</td>
          <td>{dateAdded}</td>
          <td></td>
          {/* <td className="srv-conf-wrapper left" align="right">
            <Button
              type="button"
              onClick={() => tableFuncs[0](row.ID)}
              className="px-3 mx-2 py-0"
            >
              AVAIL
            </Button>
            <Button
              type="button"
              onClick={() => tableFuncs[1](row.ID)}
              className="px-3 py-0"
            >
              VIEW
            </Button>
          </td> */}
        </tr>
      );
    } else if (tableType === "free_service_list") {
      return (
        <tr key={row.ID}>
          <td>{row.CUSTOMER}</td>
          {/* <td className="srv-conf-wrapper">
                        <Button type="button" onClick={() => tableFuncs[0](row.ID)} className="px-3 py-0">AVAIL</Button>
                    </td> */}
        </tr>
      );
    } else if (tableType === "sample_transactions_receipt") {
      return (
        <tr key={row.booking_id}>
          <td>{row.date}</td>
          <td>{row.branch}</td>
          <td>{row.customer_name}</td>
          <td>{row.booking_id}</td>
          <td>{row.or_no}</td>
          <td>P {row.grand_total.replace(/\d(?=(\d{3})+\.)/g, "$&,")}</td>
          {/* <td>{row.derm_tech}</td> */}
          <td className="srv-conf-wrapper">
            <Button
              type="button"
              onClick={() => tableFuncs[0](row.booking_id, row)}
              className="px-3 py-0"
            >
              VIEW
            </Button>
          </td>
        </tr>
      );
    } else if (tableType === "sample_daily_services_rendered") {
      return (
        <tr key={row.ID}>
          <td>{formatDate(row.DATE)}</td>
          <td>{row.BRANCH}</td>
          <td>{row.CUSTOMER}</td>
          <td>{row.SERVICE}</td>
          <td>{numberFormat(row.PRICE)}</td>
          <td>{row.REMARKS}</td>
          <td>{row.DERM_TECH}</td>
        </tr>
      );
    } else if (tableType === "daily_transactions") {
      return (
        <tr key={row.ID}>
          <td>{formatDate(row.DATE)}</td>
          <td>{row.BRANCH}</td>
          <td>{row.CUSTOMER}</td>
          <td>{row.PARTICULAR}</td>
          <td>{row.NAME}</td>
          <td>{numberFormat(row.PRICE)}</td>
          <td>{row.REMARKS}</td>
          <td>{row.DERM_TECH}</td>
        </tr>
      );
    } else if (tableType === "customer_booking") {
      /* booking tab */
      return (
        <tr key={row.id}>
          <td>{formatDate(row.DATE_BOOKED)}</td>
          <td>{row.BRANCH}</td>
          <td>{row.DERM_TECH}</td>
          <td className="srv-conf-wrapper">
            <Button
              type="button"
              onClick={() => tableFuncs[3][0](row.BOOKING_ID, row)}
              className="px-3 py-0"
            >
              view
            </Button>
          </td>
        </tr>
      );
    } else if (tableType === "customer_service_purchases") {
      /* service purchased tab */
      return (
        <tr key={row.id}>
          <td>{formatDate(row.DATE_PURCHASED)}</td>
          <td>{row.BRANCH}</td>
          <td>{row.NAME}</td>
          <td>
            {parseFloat(row.PRICE)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </td>
          <td>{row.DERM_TECH}</td>
        </tr>
      );
    } else if (tableType === "customer_package_purchases") {
      /* package purchased tab */
      return (
        <tr key={row.ID}>
          <td>{formatDate(row.DATE_PURCHASED)}</td>
          <td>{row.BRANCH}</td>
          <td>{row.NAME}</td>
          <td>
            {parseFloat(row.AMOUNT)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </td>
          <td>
            {parseFloat(row.BALANCE)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </td>
          {/* <td>{row.COMMISSIONED_BY}</td> */}
          <td className="btn-conf-wrapper">
            <Button
              type="button"
              disabled={row.BALANCE === "0.00"}
              onClick={() => tableFuncs[3][0](row.BOOKING_ID, row.BALANCE)}
              className="px-3 py-0"
            >
              pay now
            </Button>
          </td>
        </tr>
      );
    } else if (tableType === "customer_item_purchases") {
      /* item purchased tab */

      return (
        <tr key={row.id}>
          <td>{formatDate(row.DATE)}</td>
          <td>{row.branch_name}</td>
          <td>
            {row.quantity} {row.item}
            {row.quantity > 1 ? "s" : ""}
          </td>
          <td>
            {parseFloat(row.total_price)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </td>
          <td>{row.first_name}</td>
        </tr>
      );
    } else if (tableType === "customer_giftcert_purchases") {
      /* gift certificate purchased tab */
      function renderGCSIcon() {
        return (
          <img height={24} width={36} src={usedIcon} alt={"free-icon"}></img>
        );
      }
      return (
        <tr key={row.id}>
          <td>{formatDate(row.DATE)}</td>
          <td>{row.BRANCH}</td>
          <td>{row.NAME}</td>
          <td>{row.CODE}</td>
          <td>{row.AMOUNT}</td>
          <td>{row.DERM_TECH}</td>
          <td>
            {row.STATUS.toLowerCase() === "used" ? (
              <span>{renderGCSIcon()}</span>
            ) : row.STATUS.toLowerCase() === "purchased" ? (
              <td className="srv-conf-wrapper">
                <Button
                  type="button"
                  onClick={() => tableFuncs[0](row.GC_ID)}
                  className="px-3 py-0"
                >
                  Avail
                </Button>
              </td>
            ) : null}
          </td>
        </tr>
      );
    } else if (tableType === "customer_history") {
      function renderFreeIcon() {
        return <img height={24} src={freeIcon} alt={"free-icon"}></img>;
      }
      return (
        <tr key={row.id}>
          <td>{formatDate(row.DATE_AVAILED)}</td>
          <td>{row.BRANCH_AVAILED}</td>
          <td>{row.BRANCH_BOOKED}</td>
          <td>{row.PARTICULAR}</td>
          <td>{row.NAME}</td>
          <td>{row.DERM_TECH}</td>
          <td>{formatDate(row.DATE_PURCHASED)}</td>
          <td>
            <span>{row.is_free === "1" ? renderFreeIcon() : ""}</span>
          </td>          
        </tr>
      );
    } else if (tableType === "item_history") {
      return (
        <tr key={row.id}>
          <td>{formatDate(row.DATE)}</td>
          <td>{row.PRODUCT_NAME}</td>
          <td>{row.TYPE}</td>
          <td>{row.BRANCH}</td>
          <td className="text-end pe-4">{row.INVENTORY_QUANTITY}</td>
          <td className="text-end pe-4">{row.QUANTITY}</td>
          <td className="text-end pe-4">{row.REMAINING_QUANTITY}</td>
          <td>{row.CUSTOMER}</td>
          <td>{row.ADDED_BY}</td>
          <td>{row.REMARKS}</td>
          {/* <td>{row.DERM_TECH}</td> */}
        </tr>
      );
    }
  };

  // this might be useless pero it will be useful later
  const finalTable = () => {
    if (tableType === "customer") {
      return tableData.map((rawRow, index) => {
        // if (index >= ((currentPage - 1) * 11) && index < (currentPage * 11)) {
        return rowFields(rawRow);
        // }
      });
    } else if (tableType === "employee") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 11 && index < currentPage * 11) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "inventory") {
      return tableData.map((rawRow, index) => {
        // if (index >= (currentPage - 1) * 11 && index < currentPage * 11) {
          return rowFields(rawRow);
        // }
      });
    } else if (tableType === "service") {
      return tableData.map((rawRow, index) => {
        // if (index >= (currentPage - 1) * 11 && index < currentPage * 11) {
          return rowFields(rawRow);
        // }
      });
    } else if (tableType === "package") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 11 && index < currentPage * 11) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "expenses") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 9 && index < currentPage * 9) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "customer_booking") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 5 && index < currentPage * 5) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "customer_available_service") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 5 && index < currentPage * 5) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "available_gift_certificates") {
      return tableData.map((rawRow, index) => {
        // if (index >= ((currentPage - 1) * 11) && index < (currentPage * 11)) {
        return rowFields(rawRow);
        // }
      });
    } else if (tableType === "sample_purchased_gift_certificates") {
      return tableData.map((rawRow, index) => {
        // if (index >= ((currentPage - 1) * 11) && index < (currentPage * 11)) {
        return rowFields(rawRow, index);
        // }
      });
    } else if (tableType === "sample_used_gift_certificates") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 11 && index < currentPage * 11) {
          return rowFields(rawRow, index);
        }
      });
    }
    // else if (tableType === 'customer_purchase') {
    //     return (
    //         tableData.map((rawRow) => {
    //             if (rawRow.id > ((currentPage - 1) * 11) && rawRow.id <= (currentPage * 11)) {
    //                 return (
    //                     rowFields(rawRow)
    //                 )
    //             }
    //         })
    //     )
    // }

    /* customer details: service purchased tab */
    else if (tableType === "customer_service_purchases") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 5 && index < currentPage * 5) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "customer_package_purchases") {
      /* customer details: package purchased tab */
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 5 && index < currentPage * 5) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "customer_item_purchases") {
      /* customer details: item purchased tab */
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 5 && index < currentPage * 5) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "customer_giftcert_purchases") {
      /* customer details: GCS purchased tab */
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 5 && index < currentPage * 5) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "customer_history") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 5 && index < currentPage * 5) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "item_history") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 10 && index < currentPage * 10) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "sample_commission_report") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 11 && index < currentPage * 11) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "sample_balance_report") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 11 && index < currentPage * 11) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "sample_customer_report") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 11 && index < currentPage * 11) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "free_service_report") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 11 && index < currentPage * 11) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "sample_daily_sales_report") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 9 && index < currentPage * 9) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "sales_payment_history") {
      return tableData?.map((rawRow) => {
        return rowFields(rawRow);
      });
    } else if (tableType === "sample_daily_products_purchased_report") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 9 && index < currentPage * 9) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "sample_transactions_receipt") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 9 && index < currentPage * 9) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "sample_daily_services_rendered") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 9 && index < currentPage * 9) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "daily_transactions") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 5 && index < currentPage * 5) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "balance") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 11 && index < currentPage * 11) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "customer_balance_details") {
      return tableData.map((rawRow, index) => {
        // if (index >= ((currentPage - 1) * 11) && index < (currentPage * 11)) {
        return rowFields(rawRow);
        // }
      });
    } else if (tableType === "sample_free_service") {
      return tableData.map((rawRow, index) => {
        // if (index >= ((currentPage - 1) * 11) && index < (currentPage * 11)) {
        return rowFields(rawRow);
        // }
      });
    }else if (tableType === "sample_free_service_history") {
      return tableData.map((rawRow, index) => {
        // if (index >= ((currentPage - 1) * 11) && index < (currentPage * 11)) {
        return rowFields(rawRow);
        // }
      });
    } else if (tableType === "free_service_list") {
      return tableData.map((rawRow, index) => {
        if (index >= (currentPage - 1) * 11 && index < currentPage * 11) {
          return rowFields(rawRow);
        }
      });
    } else if (tableType === "transactions_receipt") {
      return tableData.map((rawRow, index) => {
        // if (index >= ((currentPage - 1) * 11) && index < (currentPage * 11)) {
        return rowFields(rawRow);
        // }
      });
    }
  };

  //display footer of a particular table
  const showFooter = () => {
    return (
      <tfoot>
        {tableType === "sample_daily_sales_report" && (
          <tr>
            <td></td>
            <td></td>
            <td>TOTAL</td>
            <td>{numberFormat(footerData.sub_total || 0)}</td>
            <td>{numberFormat(footerData.discount || 0)}</td>
            <td>{numberFormat(footerData.grand_total || 0)}</td>
            <td>{numberFormat(footerData.total_paid_amount || 0)}</td>
            <td>{numberFormat(footerData.total_balance || 0)}</td>
            <td></td>
          </tr>
        )}

        {tableType === "sample_daily_products_purchased_report" && (
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>TOTAL</td>
            <td>{footerData.total_quantity || 0}</td>
            <td>{numberFormat(footerData.grand_total || 0)}</td>
            <td></td>
          </tr>
        )}

        {tableType === "sales_payment_history" && (
          
            <tr>
              <td></td>
              <td></td>
              <td >{numberFormat(footerData.total || 0)}</td>
              <td >
                Balance: {numberFormat(footerData.balance || 0)}
              </td>
            </tr>
          
        )}

        {tableType === "sample_daily_services_rendered" && (
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>TOTAL</td>
            <td>{numberFormat(footerData || 0)}</td>
            <td></td>
            <td></td>
          </tr>
        )}

        {tableType === "daily_transactions" && (
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>TOTAL</td>
              <td>{numberFormat(footerData || 0)}</td>
              <td></td>
              <td></td>
            </tr>
        )}

        {tableType === "sample_transactions_receipt" && (
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>TOTAL</td>
            <td>
              P {footerData.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </td>
            <td></td>
          </tr>
        )}

        {tableType === "customer_balance_details" && (
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>TOTAL BALANCE</td>
            <td>{numberFormat(footerData || 0)}</td>
          </tr>
        )}
      </tfoot>
    );
  };

  return (
    <>
      <div
        className={
          tableType.includes("customer_") === true
            ? "elixir-table-wrapper table-max"
            : "elixir-table-wrapper table-max-2"
        }
        // className="tableFixHead"
      >
        <Table
          striped
          // responsive
          size="sm"
          className={
            tableType.includes("report") === true
              ? "text-center elixir-table fixed-table report-table"
              : "text-center elixir-table fixed-table "
          }
        >
          {/* HEADERS */}
          <thead > 
            <tr>
              {tableHeaders.map((header) => {
                return <th >{header}</th>;
              })}
            </tr>
          </thead>

          {/* DATA */}
            <tbody
              className={
                tableType.includes("customer_") === true
                  ? "scrollable "
                  : "scrollable "
              }
            >
              {finalTable()}
            </tbody>
            {/* if the table has a footer, display them */}
            {hasFooter && showFooter()}
        </Table>
      </div>
    </>
  );
}

export default TableTemplate;
